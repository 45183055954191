import logo from './logo.svg';
import './App.css';
import Main from './Main';
import React, { useState, useEffect, useRef, useContext, createContext } from "react";
import { Route, HashRouter, Routes } from "react-router-dom";
import Project from './Project';
import Resume from './Resume';

const App = () => {
  return (

   <HashRouter>
    <Routes>
      <Route path="/" Component={Main}/> {/* 👈 Renders at /#/app/ */}
      <Route path="/composizioni" Component={Project}/> {/* 👈 Renders at /#/app/ */}
      <Route path="/resume" Component={Resume}/> {/* 👈 Renders at /#/app/ */}
    </Routes>
  </HashRouter>
  
  );
}

export default App;
