import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useRef, useContext, createContext } from "react";

class Project extends React.Component {
    constructor() {
        super();

        this.lista=[
            {"id":1,"genere":"Musica Orchestrale","op":" Op.79","nome":"Fuga dalla Morte","link":"https://youtu.be/FxcESJ831y8",},
            {"id":2,"genere":"Musica Pianistica","op":" Op.1","nome":"Fantasia per Pianoforte","link":"https://youtu.be/3wPFIZ4O2UU",},
            {"id":3,"genere":"Musica da Camera","op":" Op.6","nome":"Fantasia per Clarinetto","link":"https://youtu.be/JMeOXbAOV_8",},
            {"id":4,"genere":"Musica Pianistica","op":" Op.2","nome":"Notturno per Pianoforte","link":"https://youtu.be/XNPkrrXcTNo",},
            {"id":5,"genere":"Musica da Camera","op":" Op.3","nome":"Quartetto di Clarinetti","link":"https://youtu.be/_7UloIvrmmQ",},
            {"id":6,"genere":"Musica Pianistica","op":" Op.5","nome":"Fantasia e Realtà","link":"https://youtu.be/gJB3zbZrTG4",},
            {"id":7,"genere":"Musica Orchestrale","op":" Op.78","nome":"Valzer Orchestrale","link":"https://youtu.be/rW4V3STauTg",},
            {"id":8,"genere":"Musica Pianistica","op":" Op.12","nome":"Sonata no.1","link":" https://youtu.be/WvHJYZrSAK8",},
           

        ];

        this.genere=["Musica Orchestrale","Musica da Camera","Musica Pianistica"]
        
    }   



  render() {
        
  return (
    <div class="d-flex flex-column h-100">
        <main class="flex-shrink-0">
   
        <nav class="navbar navbar-expand-lg navbar-light bg-white py-3">
                <div class="container px-5">
                    <a class="navbar-brand" href="index.html"><span class="fw-bolder text-primary"><img src="logo.png" width="50%"/></span></a>
                        {/*<button class="navbar-toggler" type="button" data-bs-toggle="collapse" 
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
  <span class="navbar-toggler-icon"></span></button>*/}
                    <div class="collapse_navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                            <li class="nav-item"><a class="nav-link" href="/">Home</a></li>
                            <li class="nav-item"><a class="nav-link" href="/#/composizioni">Composizioni</a></li>
                            {/*<li class="nav-item"><a class="nav-link" href="/#/resume">CV</a></li>*/}
                        </ul>
                    </div>
                </div>
            </nav>



            <section class="py-5">
             
                <div class="container px-5 mb-5">
                    <div class="text-center mb-5">
                        <h1 class="display-5 fw-bolder mb-0"><span class="text-gradient d-inline">Musica Orchestrale</span></h1>
                    </div>
                 
                 {this.lista.filter(x=> {return x.genere==="Musica Orchestrale"}).map((ele, index)=>{

                 return(
                    
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-11 col-xl-9 col-xxl-8">
                     
                            <div class="card overflow-hidden shadow rounded-4 border-0 mb-2">
                                <div class="card-body p-0">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="p-1 px-3">
                                            <h4 class="fw-bolder">{ele.op}</h4>
                                        </div>
                                        <div class="p-1">
                                            <h4 class="fw-bolder"> {ele.nome}</h4>
                                        </div>
                                        <div class="p-1 px-4">
                                            <a href={ele.link} target="xxxMIo"><img src="assets/yt.webp"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div> 
                    </div> 
                    )})}
            
            <div class="text-center mb-5">
                        <h1 class="display-5 fw-bolder mb-0"><span class="text-gradient d-inline">Musica Pianistica</span></h1>
                    </div>
                 
                 {this.lista.filter(x=> {return x.genere==="Musica Pianistica"}).map((ele, index)=>{

                 return(
                    
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-11 col-xl-9 col-xxl-8">
                     
                            <div class="card overflow-hidden shadow rounded-4 border-0 mb-2">
                                <div class="card-body p-0">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="p-1 px-3">
                                            <h4 class="fw-bolder">{ele.op}</h4>
                                        </div>
                                        <div class="p-1">
                                            <h4 class="fw-bolder"> {ele.nome}</h4>
                                        </div>
                                        <div class="p-1 px-4">
                                            <a href={ele.link} target="xxxMIo"><img src="assets/yt.webp"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div> 
                    </div> 
                    )})}
            

            <div class="text-center mb-5">
                        <h1 class="display-5 fw-bolder mb-0"><span class="text-gradient d-inline">Musica da Camera</span></h1>
                    </div>
                 
                 {this.lista.filter(x=> {return x.genere==="Musica da Camera"}).map((ele, index)=>{

                 return(
                    
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-11 col-xl-9 col-xxl-8">
                     
                            <div class="card overflow-hidden shadow rounded-4 border-0 mb-2">
                                <div class="card-body p-0">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="p-1 px-3">
                                            <h4 class="fw-bolder">{ele.op}</h4>
                                        </div>
                                        <div class="p-1">
                                            <h4 class="fw-bolder"> {ele.nome}</h4>
                                        </div>
                                        <div class="p-1 px-4">
                                            <a href={ele.link} target="xxxMIo"><img src="assets/yt.webp"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div> 
                    </div> 
                    )})}

                    </div> 
            </section>

        </main>
        <footer class="bg-white py-4 mt-auto">
            <div class="container px-5">
                <div class="row align-items-center justify-content-center flex-column flex-sm-row">
                    <div class="col-auto"><div class="small m-0">Copyright &copy; www.davidetaranto.com 2023</div></div>
               
                </div>
            </div>
        </footer>
       </div>
  );
}
}
export default Project;
